<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="select">
      <div style="margin: 10px 0; color: red">总质押量: {{ cpleCount }}</div>
      <div>
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 240px; margin-right: 10px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input placeholder="请输入手机号" v-model="phone"></el-input
        ><el-input placeholder="请输入钱包地址" v-model="walletUrl"></el-input>
        <el-button type="primary" @click="SearchList">搜索</el-button
        ><el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        height="100%"
        @sort-change="changeTableSort"
      >
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="phone"
          label="用户手机号"
          width="130"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="cpleAddress" label="用户钱包地址" width="250">
        </el-table-column>
        <el-table-column
          prop="cple"
          label="质押CPLE数量"
          :sortable="'custom'"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="txid" label="Txid"> </el-table-column>
        <el-table-column
          prop="pledgetime"
          label="质押时间"
          :sortable="'custom'"
        >
        </el-table-column>
        <el-table-column prop="pledgeExamine" label="操作人"> </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100"
          align="center"
          v-if="isShow"
        >
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >解除质押</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
        :page="currentPage"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";

import { format_time_date } from "@/util/funBusiness";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    // order 0 降序，1是生序
    return {
      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "CPLE质押",
        },
        {
          name: "质押中",
        },
      ], // 面包屑数据

      cpleCount: "", // 总质押量
      phone: "",
      walletUrl: "",
      valueArea: "",
      options: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      tableData: [],

      form: {
        txid: "",
      },

      internationType: false,
      role: "",
      phoneAreaROLE: "",

      isShow: false,
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    let user = localStorage.getItem("userName");
    user == "17631123581" ? (this.isShow = true) : (this.isShow = false);

    showLoading();
    const opt = {
      url: reqUrl.getCplePledgeList,
      method: "POST",
      params: JSON.stringify({
        cpleAddress: "",
        cpleStatus: "0",
        nonce: "",
        pageNum: 0,
        pageSize: 0,
        phone: "",
        phoneArea: this.phoneAreaROLE,
        sign: "",
        token: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          hideLoading();

          if (res.data.data.list.length > 0) {
            res.data.data.list.map((item) => {
              item.pledgetime = format_time_date(item.pledgetime);
              if (item.pledgeExamine == "qcxAdmin") {
                item.pledgeExamine = "趣出行管理员";
              } else if (item.pledgeExamine == "auto") {
                item.pledgeExamine = "自动";
              }
            });

            this.cpleCount = res.data.data.list[0].sumCple;
          } else {
            this.cpleCount = 0;
          }
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }

        hideLoading();
      },
      errFunc: (err) => {
        this.cpleCount = 0;
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    // 查询
    SearchList() {
      if (this.phone || this.walletUrl || this.valueArea) {
        showLoading();
        const opt = {
          url: reqUrl.getCplePledgeList,
          method: "POST",
          params: JSON.stringify({
            cpleAddress: this.walletUrl,
            cpleStatus: "0",
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            token: "",
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;

              hideLoading();

              if (res.data.data.list.length > 0) {
                res.data.data.list.map((item) => {
                  item.pledgetime = format_time_date(item.pledgetime);
                  if (item.pledgeExamine == "qcxAdmin") {
                    item.pledgeExamine = "趣出行管理员";
                  } else if (item.pledgeExamine == "auto") {
                    item.pledgeExamine = "自动";
                  }
                });

                this.cpleCount = res.data.data.list[0].sumCple;
              } else {
                this.cpleCount = 0;
              }
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }

            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    handlePageChange(data) {
      localStorage.setItem("requestURLIngdataIndex", data.pageIndex);
      localStorage.setItem("requestURLIngdataSize", data.pageSize);

      showLoading();
      const opt = {
        url: reqUrl.getCplePledgeList,
        method: "POST",
        params: JSON.stringify({
          cpleAddress: this.walletUrl,
          cpleStatus: "0",
          nonce: "",
          numOrder: localStorage.getItem("sortableColumn"),
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          sign: "",
          token: "",
        }),
        resFunc: (res) => {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          this.currentPage = data.pageIndex;

          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }

          if (res.data.data.list.length > 0) {
            res.data.data.list.map((item) => {
              item.pledgetime = format_time_date(item.pledgetime);
              if (item.pledgeExamine == "qcxAdmin") {
                item.pledgeExamine = "趣出行管理员";
              } else if (item.pledgeExamine == "auto") {
                item.pledgeExamine = "自动";
              }
            });

            this.cpleCount = res.data.data.list[0].sumCple;
          } else {
            this.cpleCount = 0;
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleClick(row) {
      this.$prompt("请输入内容", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          const opt = {
            url: reqUrl.unCplePledgeUrl,
            method: "POST",
            params: JSON.stringify({
              cpleAddress: row.cpleAddress,
              id: row.id,
              reason: value,
              txid: "",
              type: "0",
              unPledgeExamine: "测试",
              uncple: row.cple,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
                showLoading();
                const opt = {
                  url: reqUrl.getCplePledgeList,
                  method: "POST",
                  params: JSON.stringify({
                    cpleAddress: "",
                    cpleStatus: "0",
                    nonce: "",
                    order: "",
                    pageNum:
                      (localStorage.getItem("requestURLIngdataIndex") || 1) * 1,
                    pageSize:
                      (localStorage.getItem("requestURLIngdataSize") || 1) * 1,
                    phone: "",
                    phoneArea: this.phoneAreaROLE,
                    sign: "",
                    token: "",
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code == 0) {
                      this.tableData = res.data.data.list;
                      this.pageLength = res.data.data.total;
                      this.pageSize = res.data.data.pageSize;
                      this.currentPage =
                        (localStorage.getItem("requestURLIngdataIndex") || 1) *
                        1;

                      hideLoading();

                      if (res.data.data.list.length > 0) {
                        res.data.data.list.map((item) => {
                          item.pledgetime = format_time_date(item.pledgetime);
                          if (item.pledgeExamine == "qcxAdmin") {
                            item.pledgeExamine = "趣出行管理员";
                          } else if (item.pledgeExamine == "auto") {
                            item.pledgeExamine = "自动";
                          }
                        });

                        this.cpleCount = res.data.data.list[0].sumCple;
                      } else {
                        this.cpleCount = 0;
                      }
                    }

                    if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }

                    hideLoading();
                  },
                  errFunc: (err) => {
                    this.cpleCount = 0;
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    console.log(err, "err1");
                    hideLoading();
                  },
                };
                request(opt);
              } else if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },

    changeTableSort(column) {
      if (column.order == "ascending") {
        localStorage.setItem("sortableColumn", 1);
        showLoading();
        // 升序
        const opt = {
          url: reqUrl.getCplePledgeList,
          method: "POST",
          params: JSON.stringify({
            cpleAddress: this.walletUrl,
            cpleStatus: "0",
            nonce: "",
            order: 1,
            numOrder: 1,
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            token: "",
          }),
          resFunc: (res) => {
            hideLoading();
            console.log(res.data);
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }

            if (res.data.data.list.length > 0) {
              res.data.data.list.map((item) => {
                item.pledgetime = format_time_date(item.pledgetime);
                if (item.pledgeExamine == "qcxAdmin") {
                  item.pledgeExamine = "趣出行管理员";
                } else if (item.pledgeExamine == "auto") {
                  item.pledgeExamine = "自动";
                }
              });

              this.cpleCount = res.data.data.list[0].sumCple;
            } else {
              this.cpleCount = 0;
            }
          },
          errFunc: (err) => {
            this.cpleCount = 0;
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else if (column.order == "descending") {
        localStorage.setItem("sortableColumn", 0);
        showLoading();
        // 降序
        const opt = {
          url: reqUrl.getCplePledgeList,
          method: "POST",
          params: JSON.stringify({
            cpleAddress: this.walletUrl,
            cpleStatus: "0",
            nonce: "",
            order: 0,
            numOrder: 0,
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            token: "",
          }),
          resFunc: (res) => {
            hideLoading();
            console.log(res.data);
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }

            if (res.data.data.list.length > 0) {
              res.data.data.list.map((item) => {
                item.pledgetime = format_time_date(item.pledgetime);
                if (item.pledgeExamine == "qcxAdmin") {
                  item.pledgeExamine = "趣出行管理员";
                } else if (item.pledgeExamine == "auto") {
                  item.pledgeExamine = "自动";
                }
              });

              this.cpleCount = res.data.data.list[0].sumCple;
            } else {
              this.cpleCount = 0;
            }
          },
          errFunc: (err) => {
            this.cpleCount = 0;
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      }
    },

    reset() {
      if (this.phone || this.walletUrl || this.valueArea) {
        this.phone = "";
        this.walletUrl = "";
        this.valueArea = "";
        showLoading();
        const opt = {
          url: reqUrl.getCplePledgeList,
          method: "POST",
          params: JSON.stringify({
            cpleAddress: "",
            cpleStatus: "0",
            nonce: "",
            order: "",
            pageNum: (localStorage.getItem("requestURLIngdataIndex") || 1) * 1,
            pageSize: 0,
            phone: "",
            phoneArea: this.phoneAreaROLE,
            sign: "",
            token: "",
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              this.currentPage =
                (localStorage.getItem("requestURLIngdataIndex") || 1) * 1;

              hideLoading();

              if (res.data.data.list.length > 0) {
                res.data.data.list.map((item) => {
                  item.pledgetime = format_time_date(item.pledgetime);
                  if (item.pledgeExamine == "qcxAdmin") {
                    item.pledgeExamine = "趣出行管理员";
                  } else if (item.pledgeExamine == "auto") {
                    item.pledgeExamine = "自动";
                  }
                });

                this.cpleCount = res.data.data.list[0].sumCple;
              } else {
                this.cpleCount = 0;
              }
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }

            hideLoading();
          },
          errFunc: (err) => {
            this.cpleCount = 0;
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },

  destroyed() {
    localStorage.removeItem("sortableColumn");
  },
};
</script>

<style lang="scss" scoped>
.bread {
  height: 20px;
}

.select {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-select {
    margin-right: 10px;
  }

  .el-input {
    width: 250px;
    margin-right: 10px;
  }
}
</style>
